<script>
/**
 * Logs Data component
 */
import moment from "moment";
import EditModal from "./update-log.vue";
import { authComputed, logsMethods } from "@/state/helpers";
export default {
  props: ["logs", "causes", "actions", "operations", "busy", "loaderStatus"],
  components: {
    EditModal,
  },
  data() {
    return {
      log: {},
      logsList: [],
    };
  },
  computed: {
    ...authComputed,
    fieldsComputed() {
      if (this.loggedIn.user.role != "USER") {
        return [
          {
            key: "date",
            sortable: false,
            label: this.$t("dataTable.dateTime.text"),
          },
          {
            key: "alertedAt",
            sortable: false,
            label: this.$t("dataTable.dateAlert.text"),
          },
          { key: "serial", sortable: false, label: "Serial" },
          {
            key: "user",
            sortable: false,
            label: this.$t("dataTable.user.text"),
          },
          {
            key: "state",
            sortable: false,
            label: this.$t("dataTable.status.text"),
          },
          {
            key: "action",
            sortable: false,
            label: this.$t("dataTable.action.text"),
          },
          {
            key: "operation",
            sortable: false,
            label: "operation",
          },
          {
            key: "cause",
            sortable: false,
            label: this.$t("dataTable.cause.text"),
          },
          {
            key: "value",
            sortable: false,
            label: this.$t("dataTable.value.text"),
          },
          {
            key: "savedDoses",
            sortable: false,
            label: this.$t("dataTable.savedDoses.text"),
          },
          {
            key: "timeAquit",
            sortable: false,
            label: "TR",
          },
          {
            key: "note",
            sortable: false,
            label: this.$t("dataTable.note.text"),
          },
          /* {
            key: "actions",
            sortable: false,
            label: this.$t("dataTable.tools.text"),
            class: "tdClass",
          }, */
        ];
      } else {
        return [
          {
            key: "date",
            sortable: false,
            label: this.$t("dataTable.dateTime.text"),
          },
          {
            key: "alertedAt",
            sortable: false,
            label: this.$t("dataTable.dateAlert.text"),
          },
          { key: "serial", sortable: false, label: "Serial" },
          {
            key: "user",
            sortable: false,
            label: this.$t("dataTable.user.text"),
          },
          {
            key: "state",
            sortable: false,
            label: this.$t("dataTable.status.text"),
          },
          {
            key: "action",
            sortable: false,
            label: this.$t("dataTable.action.text"),
          },
          {
            key: "operation",
            sortable: false,
            label: "operation",
          },
          {
            key: "cause",
            sortable: false,
            label: this.$t("dataTable.cause.text"),
          },
          {
            key: "value",
            sortable: false,
            label: this.$t("dataTable.value.text"),
          },
          {
            key: "savedDoses",
            sortable: false,
            label: this.$t("dataTable.savedDoses.text"),
          },
          {
            key: "note",
            sortable: false,
            label: this.$t("dataTable.note.text"),
          },
          {
            key: "timeAquit",
            sortable: false,
            label: "TR",
          },
        ];
      }
    },
  },
  mounted() {},
  methods: {
    ...logsMethods,
    showModalEdit() {
      this.$bvModal.show("edit-log-modal");
    },
    hideModalEdit() {
      this.$bvModal.hide("edit-log-modal");
    },
    edit(log) {
      this.updateLog(log)
        .then(() => {
          this.makeToast("Update log", "log has been updated", "success");
        })
        .catch((error) => {
          this.makeToast("Update log", error, "danger");
        });
    },
    passDevice(log) {
      this.log = log;
      this.showModalEdit();
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    rowClicked(record) {
      this.$router.push({
        name: "device",
        params: { serial: record.serial },
      });
    },
  },
  watch: {
    logs(newVal) {
      this.logsList = [];
      newVal.map((obj) => {
        let savedDosesFiltered = obj.savedDoses.map(obj=>{
          return `${obj.vaccin}: <b>${obj.number}</b>`
        })
        this.logsList.push({
          ...obj,
          timeAquit: obj.timeAquit ? Math.floor(obj.timeAquit / 60) + 'h' + obj.timeAquit % 60 + 'min' : '-',
          user: obj.user.fullName,
          state: obj.state ? obj.state.name : "",
          cause: obj.cause ? obj.cause.name : "",
          operation: obj.operation ? obj.operation.name : "",
          action: obj.action ? obj.action.name : "",
          savedDoses: savedDosesFiltered.join('<br />'),
          date: moment(obj.createdAt).format("DD-MM-YYYY HH:mm:ss"),
          alertedAt: moment(obj.alertedAt).format("DD-MM-YYYY HH:mm:ss"),
        });
      });
    },
  },
};
</script>

<template>
  <div
    class="table-responsive mb-3 bg-white rounded-lg"
    style="min-height: 430px"
  >
    <b-table
      class="mb-0"
      hover
      show-empty
      centred
      :busy="busy"
      :empty-text="$t('dataTable.noLogs.text')"
      :items="logsList"
      :fields="fieldsComputed"
      v-bind:style="{ cursor: `pointer` }"
      stacked="sm"
      @row-clicked="rowClicked"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <strong>{{ $t("dataTable.loading.text") }}</strong>
        </div>
      </template>
      <template v-slot:cell(state)="row">
        <div
          class="badge font-size-12"
          :class="{
            'badge-soft-warning': `${row.value}` === 'NEW',
            'badge-soft-info': `${row.value}` === 'OPENED',
            'badge-soft-secondary': `${row.value}` === 'SUSPENDED',
            'badge-soft-danger': `${row.value}` === 'CLOSED',
            'badge-soft-dark': `${row.value}` === 'IGNORED',
          }"
        >
          {{ row.value }}
        </div>
      </template>
      <template v-slot:cell(value)="row">
        <div
          class="font-size-14 text-center font-weight-bold"
          :class="{
            'text-danger': `${row.value}` > 8,
            'text-primary': `${row.value}` < 2,
          }"
        >
          {{ row.value }}
        </div>
      </template>
      <template v-slot:cell(savedDoses)="data">
        <span v-html="data.value"></span>
      </template>
      <!-- <template v-slot:cell(actions)="data">
        <div class="d-inline-flex align-items-center">
          <b-button
            type="button"
            class="mr-2"
            variant="light"
            @click="passDevice(logs[data.index])"
            >{{ $t("buttons.edit.text") }}
          </b-button>
        </div>
      </template> -->
    </b-table>
    <EditModal
      v-bind:log="log"
      :causes="causes"
      :operations="operations"
      :actions="actions"
      @editLog="edit"
    />
  </div>
</template>
<style>
.tdClass {
  text-align: center;
}
</style>