<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import LogsData from "./logs-data";

import {
  layoutComputed,
  authComputed,
  logsMethods,
  actionsMethods,
  operationsMethods,
  causesMethods,
  logsComputed,
  actionsComputed,
  operationsComputed,
  causesComputed,
} from "@/state/helpers";
/**
 * Logs component
 */
export default {
  page: {
    title: "Logs",
    meta: [{ name: "Logs" }],
  },
  components: {
    Layout,
    PageHeader,
    LogsData,
  },
  mounted() {
    this.retrieveLogs({}).then(async () => {
      await this.retrieveCauses();
      await this.retrieveActions();
      await this.retrieveOperations();
    });
  },
  data() {
    return {
      title: "titles.logs.text",

      busy: true,

      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],

      loaderDashboard: false,

      logsData: [],
      causesData: [],
      actionsData: [],
      operationsData: [],

      logsFilter: {
        serial: null,
      },
      errFilter: null,
      searchStatus: false,

      dates: [],
      fromTime: "00:00",
      toTime: "00:00",
      fromContext: null,
      toContext: null,
      minDate: "2000-01-01",
      menu: false,

      exportStatus: false,
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...logsComputed,
    ...actionsComputed,
    ...operationsComputed,
    ...causesComputed,

    dateRangeText() {
      if (this.dates.length > 0) {
        return this.dates.join(" ~ ");
      } else {
        return "Export XLS !";
      }
    },
    items() {
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.logs.text"),
          active: true,
        },
      ];
    },
  },
  methods: {
    ...logsMethods,
    ...actionsMethods,
    ...causesMethods,
    ...operationsMethods,

    retrieveLogs({ perPage, page, serial }) {
      let params = {
        page: page ? page : this.currentPage,
        limit: perPage ? perPage : this.perPage,
        sortBy: "createdAt:desc",
        serial: serial ? serial : this.logsFilter.serial,
      };
      let paramsFilter = this.cleanObject(params);
      this.busy = true;
      return this.getLogsBy(paramsFilter).then((logs) => {
        this.busy = false;
        return logs;
      });
    },
    retrieveCauses() {
      return this.getCausesBy({ limit: 100 }).then((causes) => {
          return causes;
        });
    },
    retrieveActions() {
      if (this.getActions.results) this.actionsData = this.getActions.results;
      else return this.getActionsBy({ limit: 100 }).then((actions) => {
          return actions;
        });
    },
    retrieveOperations() {
      return this.getOperationsBy({ limit: 100, type:1 }).then((operations) => {
          return operations;
        });
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    search() {
      if (!this.logsFilter.serial)
        this.errFilter = this.$t("msgError.obligField.text");
      else this.errFilter = "";
      if (!this.errFilter) {
        this.currentPage = 1;
        this.retrieveLogs({ ...this.logsFilter, page: 1 }).then((logs) => {
          this.searchStatus = true;
          if (logs.results.length < 1) {
            this.makeToast("Search...", "Logs not found !", "danger");
          } else {
            return;
          }
        });
      }
    },
    clearSearch() {
      this.currentPage = 1;
      (this.logsFilter = {
        serial: null,
      }),
        this.retrieveLogs({ ...this.logsFilter, page: 1 }).then(() => {
          this.searchStatus = false;
        });
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.retrieveLogs({ ...this.logsFilter });
    },
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          delete obj[key];
        }
      });
      return obj;
    },

    async exportData() {
      if (this.dates.length == 2) {
        let from = `${this.dates[0]} ${this.fromTime}`;
        let to = `${this.dates[1]} ${this.toTime}`;
        let params = {
          from: from,
          to: to,
          populate: "region,province,user,action,operation,cause,state",
          sortBy: "createdAt:desc",
        };
        if (this.dates[0] == this.dates[1]) {
          let fromHoure = this.fromContext.hours;
          let fromMinute = this.fromContext.minutes;
          let toHoure = this.toContext.hours;
          let toMinute = this.toContext.minutes;
          if (toHoure >= fromHoure) {
            if (
              toHoure > fromHoure ||
              (toHoure == fromHoure && toMinute >= fromMinute)
            ) {
              this.$refs.menu.save(this.dates);
              this.exportStatus = true;
              await this.exportXLS(params)
                .then((res) => {
                  if (res.status) this.exportStatus = false;
                  else {
                    this.exportStatus = false;
                    this.makeToast(
                      "Export",
                      this.$t("msgError.exportEmpty.text"),
                      "danger"
                    );
                  }
                })
                .catch(() => {
                  this.exportStatus = false;
                });
              this.dates = [];
              this.$refs.menu.save(this.dates);
            } else {
              this.makeToast(
                "Date",
                this.$t("msgError.periodDate.text"),
                "danger"
              );
            }
          } else {
            this.makeToast(
              "Date",
              this.$t("msgError.periodDate.text"),
              "danger"
            );
          }
        } else {
          this.$refs.menu.save(this.dates);
          this.exportStatus = true;
          await this.exportXLS(params)
            .then((res) => {
              if (res.status) this.exportStatus = false;
              else {
                this.exportStatus = false;
                this.makeToast(
                  "Export",
                  this.$t("msgError.exportEmpty.text"),
                  "danger"
                );
              }
            })
            .catch(() => {
              this.exportStatus = false;
            });
          this.dates = [];
          this.$refs.menu.save(this.dates);
        }
      }
    },
    onFromContext(ctx) {
      this.fromContext = ctx;
    },
    onToContext(ctx) {
      this.toContext = ctx;
    },
  },
  watch: {
    getLogs(newVal) {
      this.logsData = newVal.results;
    },
    getCauses(newVal) {
      this.causesData = newVal.results;
    },
    getActions(newVal) {
      this.actionsData = newVal.results;
    },
    getOperations(newVal) {
      this.operationsData = newVal.results;
    },
    dates(newVal) {
      if (newVal.length == 1) {
        this.minDate = newVal[0];
      } else {
        this.minDate = "2000-01-01";
      }
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div class="row">
      <div class="col-8 col-md-8 d-inline-flex align-items-center">
        <b-form-input
          id="stateSh"
          v-model="logsFilter.serial"
          :placeholder="$t('textInput.enterSerial.text')"
          class="mr-2 w-50"
          :state="errFilter ? false : null"
        ></b-form-input>
        <b-form-invalid-feedback style="position: absolute; top: 80%">
          {{ errFilter }}
        </b-form-invalid-feedback>
        <b-button type="button" class="mr-2" variant="secondary" @click="search"
          >{{ $t("buttons.search.text") }}
        </b-button>
        <b-button
          v-if="searchStatus"
          type="button"
          class="mr-2"
          variant="warning"
          @click="clearSearch"
          ><i class="ri-refresh-line"></i>
        </b-button>
      </div>
      <!-- <div v-if="exportStatus" class="col-4 col-md-4">
        <div
          class="d-flex justify-content-center align-items-center"
          style="position: relative; z-index: 200"
        >
          <b-spinner label="Loading..." variant="primary"></b-spinner>
        </div>
      </div> -->
      <div class="col-4 col-md-4 text-right" data-app>
        <div
          v-if="exportStatus == true"
          class="d-flex justify-content-center align-items-center  mt-2"
          style="position: relative; z-index: 200"
        >
          <b-spinner label="Loading..." variant="primary"></b-spinner>
        </div>
        <v-menu
          v-if="exportStatus == false"
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              max
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            no-title
            range
            scrollable
            color="#e52342"
            :min="minDate"
          >
            <div class="row">
              <div class="col-6 d-flex">
                <!--  {{ $t("modals.fromTime.text") }} -->
                <b-time
                  hide-header
                  locale="fr"
                  v-model="fromTime"
                  style="font-size: 4px"
                  @context="onFromContext"
                ></b-time>
              </div>
              <div class="col-6 d-flex">
                <!-- {{ $t("modals.toTime.text") }} -->
                <b-time
                  hide-header
                  locale="fr"
                  v-model="toTime"
                  style="font-size: 4px"
                  @context="onToContext"
                ></b-time>
              </div>
              <div class="col-12 text-left">
                <b-button class="mb-1" variant="success" @click="exportData()">
                  {{ $t("buttons.exportXLS.text") }}
                </b-button>
              </div>
            </div>
          </v-date-picker>
        </v-menu>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <LogsData
          v-bind:logs="logsData"
          :causes="causesData"
          :operations="operationsData"
          :actions="actionsData"
          :busy="busy"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="dataTables_paginate paging_simple_numbers float-right mb-3">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="getLogs.totalResults"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>