<template>
  <div>
    <b-modal
      id="edit-log-modal"
      ref="edit-log-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.updateLog.text')"
    >
      <div class="row">
        <!-- Operation -->
        <div class="col-12">
          <label for="operation">Operation</label>
          <b-form-select
            id="operation"
            v-model="logEdit.operation"
            :options="operationsData"
            class="mb-1"
            placeholder="Region"
            :state="err.operation ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.operation }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row">
        <!-- Action -->
        <div class="col-12">
          <label for="action">{{ $t("modals.actions.text") }}</label>
          <b-form-select
            id="action"
            v-model="logEdit.action"
            :options="actionsData"
            class="mb-1"
            placeholder="Region"
            :state="err.action ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.action }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row">
        <!-- Cause -->
        <div class="col-12">
          <label for="cause">{{ $t("modals.causes.text") }}</label>
          <b-form-select
            id="cause"
            v-model="logEdit.cause"
            :options="causesData"
            class="mb-1"
            placeholder="Province"
            :state="err.cause ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.cause }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row">
        <!-- SavedDoses -->
        <div class="col-12">
          <label for="vaccins">{{ $t("modals.savedDoses.text") }}</label>
          <div class="row">
            <div
              id="vaccins"
              v-for="(item, index) in Object.keys(savedDoses)"
              :key="index"
              class="col-3"
            >{{item}}
              <b-form-input
                type="number"
                min="0"
                v-model="logEdit.savedDoses[item]"
                :placeholder="item"
                class="mb-1"
              ></b-form-input>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Note -->
        <div class="col-12">
          <label for="note">{{ $t("modals.note.text") }}</label>
          <b-form-input
            id="note"
            v-model="logEdit.note"
            class="mb-1"
            :placeholder="$t('modals.note.text')"
            :state="err.note ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.note }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="edit(ok)">
              {{ $t("buttons.update.text") }}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()">
              {{ $t("buttons.cancel.text") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["log", "actions", "operations", "causes"],
  created() {},
  data() {
    return {
      actionsData: [
        { value: null, text: this.$t("titles.actions.text"), disabled: true },
      ],
      operationsData: [
        { value: null, text: "Operations", disabled: true },
      ],
      causesData: [
        { value: null, text: this.$t("titles.causes.text"), disabled: true },
      ],
      savedDoses: {},
      logEdit: {
        logId: null,
        operation: null,
        action: null,
        cause: null,
        note: null,
        savedDoses: null,
      },
      err: {
        operation: null,
        action: null,
        cause: null,
        note: null,
      },
    };
  },
  watch: {
    log(newVal) {
      let savedD = {};
      newVal.savedDoses.forEach(obj=>{
        savedD[obj.vaccin] = obj.number
      })
      this.savedDoses = savedD;
      this.logEdit = {
        logId: newVal.id,
        operation: newVal.operation ? newVal.operation.id : null,
        action: newVal.action ? newVal.action.id : null,
        cause: newVal.cause ? newVal.cause.id : null,
        note: newVal.note ? newVal.note : null,
        savedDoses: savedD ? savedD : null,
      };
    },
    operations(newVal) {
      let operationsList = newVal;
      operationsList.map((obj) => {
        this.operationsData.push({ value: obj.id, text: obj.name });
      });
    },
    actions(newVal) {
      let actionsList = newVal;
      actionsList.map((obj) => {
        this.actionsData.push({ value: obj.id, text: obj.name });
      });
    },
    causes(newVal) {
      let causesList = newVal;
      causesList.map((obj) => {
        this.causesData.push({ value: obj.id, text: obj.name });
      });
    },
  },
  methods: {
    cancel() {
      this.clear();
    },
    clear() {
      this.err = {
        operation: null,
        action: null,
        cause: null,
        note: null,
      };
    },
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          delete obj[key];
        }
      });
      return obj;
    },
    edit(ok) {
      if (!this.logEdit.action)
        this.err.action = this.$t("msgError.obligField.text");
      else this.err.action = "";

      if (!this.logEdit.operation)
        this.err.operation = this.$t("msgError.obligField.text");
      else this.err.operation = "";

      if (!this.logEdit.cause)
        this.err.cause = this.$t("msgError.obligField.text");
      else this.err.cause = "";

      if (!this.err.action && !this.err.cause && !this.err.operation) {
        if (
          this.logEdit.action != this.log.action ||
          this.logEdit.operation != this.log.operation ||
          this.logEdit.cause != this.log.cause
        ) {
          ok();
          let vaccinsKeys = Object.keys(this.logEdit.savedDoses);
          let vaccins = null;
          if (vaccinsKeys.length > 0)
            vaccins = vaccinsKeys.map((obj) => {
              return {
                vaccin: obj,
                number: this.logEdit.savedDoses[obj],
              };
            }); 
            this.logEdit.savedDoses = vaccins ? vaccins : null;
          let filtredLog = this.cleanObject(this.logEdit);
          this.$emit("editLog", filtredLog);
        } else {
          ok();
        }
      }
    },
  },
};
</script>