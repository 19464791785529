var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-responsive mb-3 bg-white rounded-lg",staticStyle:{"min-height":"430px"}},[_c('b-table',{staticClass:"mb-0",style:({ cursor: `pointer` }),attrs:{"hover":"","show-empty":"","centred":"","busy":_vm.busy,"empty-text":_vm.$t('dataTable.noLogs.text'),"items":_vm.logsList,"fields":_vm.fieldsComputed,"stacked":"sm"},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t("dataTable.loading.text")))])])]},proxy:true},{key:"cell(state)",fn:function(row){return [_c('div',{staticClass:"badge font-size-12",class:{
          'badge-soft-warning': `${row.value}` === 'NEW',
          'badge-soft-info': `${row.value}` === 'OPENED',
          'badge-soft-secondary': `${row.value}` === 'SUSPENDED',
          'badge-soft-danger': `${row.value}` === 'CLOSED',
          'badge-soft-dark': `${row.value}` === 'IGNORED',
        }},[_vm._v(" "+_vm._s(row.value)+" ")])]}},{key:"cell(value)",fn:function(row){return [_c('div',{staticClass:"font-size-14 text-center font-weight-bold",class:{
          'text-danger': `${row.value}` > 8,
          'text-primary': `${row.value}` < 2,
        }},[_vm._v(" "+_vm._s(row.value)+" ")])]}},{key:"cell(savedDoses)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.value)}})]}}])}),_c('EditModal',{attrs:{"log":_vm.log,"causes":_vm.causes,"operations":_vm.operations,"actions":_vm.actions},on:{"editLog":_vm.edit}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }